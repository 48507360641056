header {
  position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: auto;
	z-index: 100;
  text-align: center;
  background: none;
	transition: transform 0.5s ease;
	&:not(.post-banner){
		background: $white;
	}
	&.no-banner-img{
		background: $white;
	}
	.logo-area{
		a{
			height: 60px;
			display: block;
			width: 124px;
			img,svg{
				height: 100%;
				width: auto;
			}
		}
	}
	.main-header{
		padding: 20px 0;
	}
	&.header-initial{
		top: 0;
		transform: translateY(0);
		
	}
	&.show-header{
		top: 0;
		transform: translateY(-66px);
		background: $white;
		
	}
	&.show-header:not(.post-banner){
		transform: translateY(0);
		
	}
	&.show-header.header-initial{
		transform: translateY(0);
		.promo-bar{
			transform: translateY(0);
		}
	}
	&.show-header{
		.promo-bar{
			transform: translateY(-100%);
		}
	}
	&.hide-promo .promo-bar{
		transform: translateY(-100%);
	}
	.navbar-expand-md .navbar-nav{
		h6{
			color: $purple;
		}
		.nav-item{
			text-align: left;
			margin-top: 15px;
			@include breakpoint(md) {
				margin-left: 30px;
				margin-top: 0;
			}
		}
		 .nav-link{
			font-size: rem-calc(16);
			font-weight: $semi;
			color: #363b3f;
			padding: 0;
			@include breakpoint(md) {
				font-size: rem-calc(14);
			}
			&:hover{
				color: $purple;
			}
		}
	}
	.dropdown-toggle::after{
		border-top: 6px solid;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
		border-top-color: $primary;
	}
	.navbar-toggler{
		position: absolute;
		right: 0;
		top: -35px;
		padding: 0;
		border: none;
		.navbar-toggler-icon{
			background-image: url(
  "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%2320bec6' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
			width: 48px;
		}
	}
	.navbar-collapse{
		background: $white;
		padding: 15px;
		@include breakpoint(md) {
			background: none;
			padding: 0;
		}

	}
	.navbar-nav .dropdown-menu{
		border: none;
    border-radius: 0;
    font-size: 14px;
    padding-left: 20px;
    @include breakpoint(md) {
    	padding: 10px 20px;
    }
		*{
			font-size: 14px;
			padding: 0;
		}
		li{
			margin-bottom: 5px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		a{
			&:hover{
				background: none;
				color: $purple;
			}
		}
	}
	.dropdown-item{
		padding-left: 0;
		padding-right: 0;
		&:before{
				content: '›  ';
			}
	}
}
header{
  transform: translateY(-100%);
}

.promo-bar{
	background: $white;
	@include border(top,1px,solid,#adadad);
	@include border(bottom,1px,solid,#adadad);
	padding: 12px 0;
	transition: transform 0.5s ease;
	img{
		height: 30px;
		@include breakpoint(md){
			height: 40px;
		}
	}
}




.contact-area{
	font-size: rem-calc(14);
	a{
		margin-right: 5px;
		font-weight: $bold;
		font-size: rem-calc(14);
		i{
			margin-right: 20px;
			color: $primary;
		}
	}
}
.contact-block {
    ul{
        padding: 0;
    }
    li{
        padding: 4px 0;
        i{
            margin-right: 12px;
            margin-bottom: 0;
            color: $purple;
            width: 22px;
            height: 22px;
            font-size: 20px !important;
        }
    }
}
.banner-wrap{
	height: 400px;

	.banner-image{
		height: 100%;
		margin-top: 56px;
		background-size: cover;
		background-repeat: no-repeat;
		
		background-position: 40% center;
		 @include breakpoint(sm) {
		 	background-position: center top;
		 	margin-top: 66px;
		 }

		.container, .row{
			height: 100%;
		}
		h1{
			font-size: rem-calc(20);
		    color: $purple;
		    text-shadow: 1px 1px rgba(255,255,255,.5);
		    font-weight: $regular;
		    line-height: 1.3;
		    @include breakpoint(md) {
		    	font-size: rem-calc(40);
		    }
		}
	}
	@include breakpoint(md){
		height: 550px;
	}
	@include breakpoint(xxxl){
		height: 600px;
	}
	.rating-area{
		width: 200px;
		height: 200px;
		@include border-radius(50%);
		@include border(all,12px,solid,$white);
		background: radial-gradient(circle,white 0%,#f2f2f2 100%);
		filter: drop-shadow(0.25em 0.7em 0.95em rgba(0,0,0,0.2));
		.rating-number{
			font-size: rem-calc(56);
			font-weight: $bold;
			line-height: 56px;
		}
		.rating-text{
			font-size: rem-calc(14);
			font-weight: $bold;
		}
		.rating-lead-text{
			font-size: rem-calc(12);
			a{
				text-decoration: underline;
			}
		}
		.rating-star{
			width: 100px;
			position: relative;
			height: 20px;
			.star-rating{
				&:before{
					background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTAgNTAiIGZpbGw9IiM3ZjhjOGQiID4gICAgPHBhdGggZD0iTSAyNSAyLjI3MzQzNzUgTCAxOC42MDkzNzUgMTguNDgwNDY5IEwgMC44MTA1NDY4OCAxOS40MTc5NjkgTCAxNC42NDg0MzggMzAuNDEyMTA5IEwgMTAuMDcwMzEyIDQ3LjIyMjY1NiBMIDI1IDM3Ljc3MTQ4NCBMIDM5LjkyOTY4OCA0Ny4yMjI2NTYgTCAzNS4zNTE1NjIgMzAuNDEyMTA5IEwgNDkuMTg5NDUzIDE5LjQxNzk2OSBMIDMxLjM5MDYyNSAxOC40ODA0NjkgTCAyNSAyLjI3MzQzNzUgeiI+PC9wYXRoPjwvc3ZnPg==);
				    background-repeat: repeat-x;
				    background-size: 20px 100%;
				    -webkit-background-size: 20px 100%;
				    bottom: 0;
				    content: "";
				    display: block;
				    height: 20px;
				    left: 0;
				    position: absolute;
				    right: 0;
				    top: 0;
				    width: 100px;
				}
				span{
					display: block;
				    text-indent: -10000px;
				    width: 100px;
				    height: 20px;
				    position: absolute;
				    overflow: hidden;
				    &:before{
			    	    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTAgNTAiIGZpbGw9IiNmMWM0MGYiID4gICAgPHBhdGggZD0iTSAyNSAyLjI3MzQzNzUgTCAxOC42MDkzNzUgMTguNDgwNDY5IEwgMC44MTA1NDY4OCAxOS40MTc5NjkgTCAxNC42NDg0MzggMzAuNDEyMTA5IEwgMTAuMDcwMzEyIDQ3LjIyMjY1NiBMIDI1IDM3Ljc3MTQ4NCBMIDM5LjkyOTY4OCA0Ny4yMjI2NTYgTCAzNS4zNTE1NjIgMzAuNDEyMTA5IEwgNDkuMTg5NDUzIDE5LjQxNzk2OSBMIDMxLjM5MDYyNSAxOC40ODA0NjkgTCAyNSAyLjI3MzQzNzUgeiI+PC9wYXRoPjwvc3ZnPg==);
					    background-repeat: repeat-x;
					    background-size: 20px 100%;
					    -webkit-background-size: 20px 100%;
					    bottom: 0;
					    content: "";
					    display: block;
					    height: 20px;
					    left: 0;
					    position: absolute;
					    right: 0;
					    text-indent: 10000px;
					    top: 0;
				    }
				}
			}
		}
	}
}
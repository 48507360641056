/* ==========================================================================
   COLORS
   ========================================================================== */
	$primary : #20bec6;
	$primary-border : #1ca9b0;
	$alt : #3ad7df;
	$alt-border : #2dd5dd;
	$purple: #76397c;
	$white: #FFFFFF;
	$black: #000000;

	$dark-gray: #363B42;
	$gray: #1d1d1b;
	$light-gray: #E0E0E0;



/* ==========================================================================
   FONTS AND WEIGHTS
   ========================================================================== */
   $font: 'Exo','sans-serif';

   $elight: 100;
   $light_wgt: 200;
	$thin: 300;
	$regular: 400;
	$medium: 500;
	$semi: 600;
	$bold: 700;
	$b_black: 800;

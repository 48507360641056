input[type="text"],
input[type="email"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="range"],
input[type="file"],
textarea, .form-control,
select {
    background: $white;
    font-size: rem-calc(14);
    @include border-radius(3px);
    @include border(all,1px,solid,#CCC);
    @include transition(all,.3s,linear);
    padding: 9px 12px;
    width: 100%;
    color: $dark-gray;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    &:focus{
        border-color: $purple;
        box-shadow: none;
        outline: none;
        outline-offset: none;
    }
}

label{
    font-weight: $bold;
    font-size: rem-calc(14);
}
textarea, textarea.form-control, .form-floating > textarea.form-control{
    resize: none;
    height: 136px;
    @include breakpoint(md) {
        height: 155px;
    }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > label{
    padding: 10px 0;
}

.form-floating > label{
    font-size: rem-calc(24);
}
.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label{
    color: $dark-gray;
    font-size: rem-calc(12);
    transform:scale(1) translateY(-10px) translateX(0);
}
button:focus:not(:focus-visible){
    box-shadow: none;
}

.form-check{
    padding-left: 0;
    margin-bottom: 40px;
}
.css-checkbox, .css-radio {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}


.css-checkbox + label, .css-radio + label {
    position: relative;
    font-size: 14px;
    font-weight: $regular;
    cursor: pointer;
    // display: inline-flex;
    color: $dark-gray;
}

.css-checkbox + label::before, .css-radio + label::before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    min-width: 22px;
    height: 22px;
    background-color: $white;
    border-width: 1px;
    border-style: solid;
    border-color: #CCCCCC;
    border-radius: 6px;
    box-shadow: none;
    @include transition(all,.3s,linear);
}

.css-radio + label::before{
    @include border-radius(50%);
}
.css-checkbox:checked + label::after{
    // background-color: $primary;
    // border-color:$primary;
    opacity: 1;
}
.css-checkbox + label::after {
    content: "\e005";
    // background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.55295 11.573C5.36764 11.5704 5.18996 11.4988 5.0548 11.372L0.160698 6.47786C0.0492079 6.34201 -0.0077678 6.16956 0.000852866 5.99403C0.00947353 5.81851 0.0830741 5.65246 0.207339 5.5282C0.331603 5.40393 0.497647 5.33033 0.673172 5.32171C0.848697 5.31309 1.02115 5.37007 1.157 5.48156L5.55295 9.88625L14.843 0.587456C14.9788 0.475966 15.1513 0.41899 15.3268 0.427611C15.5024 0.436231 15.6684 0.509832 15.7927 0.634097C15.9169 0.758361 15.9905 0.924405 15.9991 1.09993C16.0078 1.27545 15.9508 1.44791 15.8393 1.58375L6.0511 11.372C5.91594 11.4988 5.73826 11.5704 5.55295 11.573Z' fill='red'/%3E%3C/svg%3E%0A");
    // background-repeat: no-repeat;
    // background-position: center center;
    font-family: icons;
    color: $primary;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    left: 3px;
    top: 2px;
    text-align: center;
    font-size: 12px;
    opacity: 0;
    @include transition(all,.3s,linear);
}

.css-radio + label::after {
    content: " ";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    left: 3px;
    top: 3px;
    text-align: center;
    height: 16px;
    width: 16px;
    background: $primary;
    @include border-radius(50%);
    @include transition(all,.3s,linear);
    opacity: 0;
}

.css-radio:checked + label::after {
    opacity: 1;
}


.form-control.is-invalid, .was-validated .form-control:invalid{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNkYzM1NDUnIHZpZXdCb3g9Jy0yIC0yIDcgNyc+PHBhdGggc3Ryb2tlPScjZGMzNTQ1JyBkPSdNMCAwbDMgM20wLTNMMCAzJy8+PGNpcmNsZSByPScuNScvPjxjaXJjbGUgY3g9JzMnIHI9Jy41Jy8+PGNpcmNsZSBjeT0nMycgcj0nLjUnLz48Y2lyY2xlIGN4PSczJyBjeT0nMycgcj0nLjUnLz48L3N2Zz4=);
    border-color: #dc3545;
}

.was-validated .form-control:valid, .form-control.is-valid{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMyOGE3NDUnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
    border-color: #28a745;
}


.error-message {
    position: relative;
    background-color: #20bec6;
    color: #fff;
    padding: 10px;
    margin-top: 10px;
    position: relative;
    border-radius: 3px;
    &:before{
        content: "";
        top: -14px;
        left: 12%;
        border: 7px solid transparent;
        border-bottom-color: #20bec6;
        height: 0;
        width: 0;
        position: absolute;
    }
}

.general-form{
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="color"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="range"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    input[type="range"],
    input[type="file"],
    textarea, .form-control,
    select {
        @include transition(all,.3s,linear);
        &:focus{
            color: #495057;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
        }
    }

}

legend{
    font-size: rem-calc(16);
    font-weight: $bold;
}

textarea.textarea-sm{
    height: 75px;
}


.login-area{
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="color"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="range"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    input[type="range"],
    input[type="file"],
    textarea, .form-control,
    select {
        border-radius: 0;
        font-size: 16px;
        &:focus{
            box-shadow: none;
            border-color: #2563eb;
        }
    }
    .btn{
        border-radius: 0;
    }
}
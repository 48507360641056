.icon-col-wrap{
	[class*='col']:first-child{
		[class*='bg-']{
			color: $purple;
		}
	}
	[class*='bg-']{
		border-radius: 10px 0 10px 10px;
		font-size: rem-calc(21);
		padding: 5px 30px;
		color: $white;
		@include breakpoint(md){
			padding: 15px 30px;
		}
	}
	.link-icon {
		@include breakpoint(md){
			margin-bottom: 15px;
		}
		img {
		    height: 65px;
		    margin: 0 30px 0 0;
		    @include breakpoint(md){
		    	height: 150px;
		    	margin: 0 auto;
		    }
		}
	}
}
body{
    font-family: $font;
    font-weight: $regular;
    font-size: rem-calc(16);
    line-height: 1.5;
    letter-spacing: rem-calc(.5);
    color: $gray;
}



h1.black, h2.black, h3.black, h4.black, h5.black, h6.black,
.h1.black, .h2.black, .h3.black, .h4.black, .h5.black, .h6.black {
    color: $black;
}



label.h2,
.heading2 {
    display: inline-block;
    font-size: 1em;
    font-weight: 600;
    color: #363b3f;
    margin: 0 0 3px
}


legend.h3 {
    font-size: 1.143em;
    padding-bottom: 5px;
    margin: 10px 0
}


h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
  margin: 0;
  font-weight: $semi;
  line-height: 1.2em;

}
h1,.h1{
    font-size: rem-calc(24);
    color: $primary;
}
h2,.h2{
    font-size: rem-calc(16);
    color: $primary;
}
h3,.h3{
    font-size: rem-calc(16);
}
h4,.h4{
    font-size: rem-calc(16);
    font-weight: $regular;
}

h5, .h5{
    font-size: rem-calc(16);
}

h6,.h6, .sub-title{
    font-size: rem-calc(16);
}

.quote-font-1{
    font-size: rem-calc(26);
    line-height: rem-calc(32);
    color: $dark-gray;
    @include breakpoint(lg) {
        font-size: rem-calc(48);
        line-height: rem-calc(60);
    }
}


img, svg {
    max-width:100%;
    height:auto;
    @include transition(all,.3s,linear);
}
svg{
    path{
        @include transition(all,.3s,linear);
    }
}
.font-thin{
    font-weight: $thin;
}
p{
    margin-bottom: rem-calc(16);
    font-size: rem-calc(18);
    &:last-child{
        margin-bottom: 0;
    }
}
a{
    text-decoration: none;
    color: $dark-gray;
    @include transition(all,.2s,linear);
    strong{
        @include transition(all,.3s,linear);
    }
    &:hover{
        color: $purple;
        text-decoration: underline;
    }
}
strong{
    font-weight: $bold;
}
ul,ol{
    list-style: none;
    padding: 0;
    margin: 0;
}
iframe{
  display: block;
  width: 100%;
}
img{
    display: block;
    object-fit: cover;
}
main{
    overflow: hidden;
}
.container{
    padding: 0 32px;
    @include breakpoint(md){
        max-width: 764px;
        padding: 0 15px;
    }
    @include breakpoint(lg){
        max-width: 960px;
    }
    @include breakpoint(xl){
        max-width: 1170px;
    }
}

.section{
    padding: 40px 0;
    @include breakpoint(lg){
        padding: 80px 0;
    }
}


.single-content-wrap{
    .sidebar-area{
        ol,ul{
            margin: 0;
            padding: 0;
            li{
                list-style: none;
            }
        }
    }
     *:not(form *){
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    
    label{
        margin-bottom: 5px;
    }
    *{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    h1,h2,h3,h4,h5,h6{
        margin-bottom: 10px;
    }
    ul, ol{
        padding-left: 16px;
    }
    ul{
         li{
            list-style: disc;
            li{
                list-style: square;
            }
        }
    }
    ol{
         li{
            list-style: decimal;
            li{
                list-style: roman;
            }
            ul{
                li{
                    list-style: disc;
                }
            }
        }
    }
    a.btn{
        margin-top: 16px;
    }
    blockquote{
        font-size: rem-calc(26);
        line-height: rem-calc(32);
        font-weight: $medium;
        @include breakpoint(xl){
            font-size: rem-calc(48);
            line-height: rem-calc(60);
        }
    }
    .single-content{
        a{
            color: $purple;
        }
    }
}


.single-content-sm-wrap.single-content-wrap{
    .single-content{
        a{
            color: $purple;
        }
    }
    .sidebar-area{
        ol,ul{
            margin: 0;
            padding: 0;
            li{
                list-style: none;
                margin-bottom: 0;
            }
        }
    }
    *:not(form *){
        margin-bottom: 10px;
        font-size: rem-calc(14);
        &:last-child{
            margin-bottom: 0;
        }
    }
    
    label{
        margin-bottom: 5px;
    }
    p{
        margin-bottom: 20px;
    }
    h1{
        font-size: rem-calc(18);
    }
    h1,h2,h3,h4,h5,h6{
        margin-bottom: 10px;
    }
    ul, ol{
        padding-left: 16px;
    }
    ul{
        margin-bottom: 30px;
         li{
            ul{
                margin-left: 0;
            }
            list-style: disc;
            margin-bottom: 5px;
            li{
                list-style: square;
            }
            ol{
                margin-left: 0;
                li{
                    list-style: decimal;
                }
            }
        }
    }
    ol{
        margin-bottom: 30px;
         li{
            list-style: decimal;
            margin-bottom: 5px;
            ol{
                margin-left: 0;
            }
            li{
                list-style: roman;
            }
            ul{
                margin-left: 0;
                li{
                    list-style: disc;
                }
            }
        }
    }
    a.btn{
        margin-top: 16px;
    }
    blockquote{
        font-size: rem-calc(26);
        line-height: rem-calc(32);
        font-weight: $medium;
        @include breakpoint(xl){
            font-size: rem-calc(48);
            line-height: rem-calc(60);
        }
    }
}



.bg-light-gray{
    background: #f2f2f2;
}
.bg-purple{
    background: $purple;
}







@media (min-width: 768px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}


.offcanvas.offcanvas-end{
    height: 100vh;
}

header:not(.post-banner) + main{
    padding-top: 100px;
}
header.post-banner + main{
    padding-top: 166px;
}
.sidebar-area{
    background-color: #f2f2f2;
    padding: 14px 16px;
    border: 1px solid #e5e5e5;
    border-radius: 9px 0 9px 9px;
    h2{
        color: $dark-gray;
    }
}

ul.custom-list{
    list-style: none;
    padding: 0;
    li{
        list-style: none;
        padding: 5px 0 5px 25px;
        position: relative;
        &:before{
            content:'';
            background: transparent url(/assets/dist/images/check.svg) no-repeat 0 7px;
            background-size: 16px 16px;
            position: absolute;
            left: 0;
            width: 16px;
            height: 20px;
            top: 0;
        }
    }
}

.text-large{
    font-size: rem-calc(25) !important;
}

.color-link{
    a{
        color: $purple !important;
    }
}

.color-alt{
    color: $alt !important;
}
.table-tab-wrap .text-small * {
  padding: 0;
}

h2:not(.h1) {
  font-weight: 600;
  line-height: 1.2em;
  margin: 0 0 10px 0;
  color: #20bec6;
  font-size: 1.5rem !important;
}

section.section-error h2 {
  color: #76397c;
}
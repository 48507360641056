.single-content-sm-wrap.single-content-wrap *:not(form *) .accordion-item{
	*{
		// font-size: rem-calc(16);	
	}
	a{
        color: $purple;
    }
	
	border-radius: 0;
	border: none;
}
.accordion-item .accordion-button{
	background: none;
	font-weight: $semi;
	border: none;
	padding: 0 0 0 15px;
	color: $primary;
	box-shadow: none;
	position: relative;
	&:hover{
		text-decoration: underline;
	}
}
.accordion-body{
	padding: 0 0 0 20px;
}

.accordion-button:not(.collapsed)::after, .accordion-button::after{
	display: none;
}
.accordion-button:not(.collapsed)::before, .accordion-button::before{
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 4px 0 4px 8px;
	border-color: transparent transparent transparent $primary;
	margin-right: 5px;
	@include transition(all,.3s,linear);
	position: absolute;
    top: 5px;
    left: 0;
}
.accordion-button:not(.collapsed):before{
	transform: rotate(90deg);
}

.sidebar-links{
	ul{
		padding: 0 !important;
		li{
			list-style: none !important;
			margin-bottom: 20px !important;
			a{
				color: #ccc !important;
				font-size: rem-calc(14);
				font-weight: $semi;
				&:hover{
					text-decoration: none;
					color: $purple !important;
				}
				&.active{
					color: $purple !important;
				}
			}
		}
	}
}
footer{
	background: #f9f9f9;
	padding: 30px 0 0;
	*{
		font-size: rem-calc(16);
		@include breakpoint(md){
			font-size: rem-calc(14);
		}
	}
	h4{
		font-size: rem-calc(16);
		font-weight: $semi;
		margin-bottom: 15px;
	}
	.rating-star{
		li{
			margin-right: 5px;
		}
		i{
			color: $purple;
			@include breakpointMax(md){
				font-size: rem-calc(16);
			}
		}
	}
	.rating-date{
		font-size: rem-calc(14);
		color: $purple;
		@include breakpoint(md){
			font-size: rem-calc(12);
		}
	}
	.rating-main{
		.rating{
			font-size: rem-calc(36);
			color: $purple;
			font-weight: $semi;
		}
		a{
			&:hover{
				color: $purple;
				text-decoration: underline;
			}
		}
	}
	.footer-top{
		border-bottom: 2px solid #e5e5e5;
		padding-bottom: 30px;
		margin-bottom: 30px;
	}
	.footer-menu-wrap{
		[class*="col"]{
			margin-bottom: 30px;
			@include breakpoint(md){
				margin-bottom: 0;
			}
		}
		ul{
			li{
				margin-bottom: 8px;
				&:last-child{
					margin-bottom: 0;
				}
				ul{
					margin-left: 0;
					
					li{
						*:before{
							content: '-  ';
						}
					}
				}
				i{
					font-size: rem-calc(20);
					color: $purple;
					margin-right: 10px;
					width: 22px;
					height: 30px;
				}
			}
		}
	}
	.disclaimer-text{
		margin-top: 30px;
		padding: 30px 0;
		p{
			font-size: rem-calc(14);
		}
	}
	.footer-bottom{
		background: #e5e5e5;
		padding: 20px 0;
		ul{
			li{
				padding-right:10px;

				&:after{
					content: '|';
					margin-left: 10px;
				}
				&:last-child{
					padding-right: 0;
					&:after{
						display: none;
					}
				}
			}
		}
	}

	.rating-review{
		@include breakpointMax(md) {
			margin-top: 25px;
		}

	}
}

.login-sign-up footer a{
	color: $purple;
}
.table-tab-wrap{
	.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
		background: $primary;
		color: $white;
		font-weight: $semi;
		font-size: rem-calc(14);
		border-color: $primary;
		padding: 10px;
	}
	.nav-tabs .nav-link{
		padding: 10px;
	}
	.nav-tabs{
		border-color: $primary;
	}
	.table-responsive{
		@include breakpointMax(md){
			@include border(all,2px,solid,$primary);
		}
	}
	table{
		background: #f2f2f2;
		th,td{
			padding: 5px 10px;
			font-size: rem-calc(14);
		}
		thead{
			background: $primary;
			th,td{
				color: $white;
				background: none;
				font-size: rem-calc(16);
				vertical-align: top;
			}
		}
		tr{
			border: 1px solid #dee2e6;
		}
	}
	.text-small {
		margin-top: 15px;
		*{
			padding: 0 15px; 
			font-size: rem-calc(12);
		}
	}
}
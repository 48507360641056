.login-form{
	.form-wrapper{
		// margin-top: 25px;
		background: rgb(242,242,242);
		@include border-radius(6px);
		padding: 15px;
	}
	a{
		color: $purple;
	}
}

// .login-area.single-content-wrap{
	
// 	.single-content *{
// 		margin-bottom: 20px;
// 		color: rgba(116,116,116);
// 		font-size: rem-calc(16);
	
// 		a{
// 			color: $purple;
// 		}
// 		h1,h2,h3{
// 			color: rgba(34,34,34);
// 			font-weight: $regular;
// 		}
// 		h1{
// 			font-size: rem-calc(24);
// 		}
// 		h2{
// 			font-size: rem-calc(20);
// 		}
// 	}
// 	label{
// 		color: $dark-gray;
// 		font-size: 16px;
// 	}

// }

.login-sign-up .single-content-wrap{
    .single-content{
        a{
            color: $purple;
        }
    }
    .sidebar-area{
        ol,ul{
            margin: 0;
            padding: 0;
            li{
                list-style: none;
            }
        }
    }
   
	label{
		color: $dark-gray;
		font-size: 16px;
	}
    *:not(form *){
        margin-bottom: 10px;
        color: rgba(116,116,116);
		font-size: rem-calc(16);
        &:last-child{
            margin-bottom: 0;
        }
    }
    h1.text-large{
    	font-size: rem-calc(24);
    }
     h1,h2,h3{
		color: rgba(34,34,34);
		font-weight: $regular;
	}
	h1{
		font-size: rem-calc(24);
	}
	h2{
		font-size: rem-calc(20);
	}
    
    label{
        margin-bottom: 5px;
    }
    p{
        margin-bottom: 20px;
    }
    h1,h2,h3,h4,h5,h6{
        margin-bottom: 10px;
    }
    ul, ol{
        padding-left: 16px;
    }
    ul{
        margin-bottom: 30px;
         li{
            ul{
                margin-left: 0;
            }
            list-style: disc;
            margin-bottom: 5px;
            li{
                list-style: square;
            }
            ol{
                margin-left: 0;
                li{
                    list-style: decimal;
                }
            }
        }
    }
    ol{
        margin-bottom: 30px;
         li{
            list-style: decimal;
            margin-bottom: 5px;
            ol{
                margin-left: 0;
            }
            li{
                list-style: roman;
            }
            ul{
                margin-left: 0;
                li{
                    list-style: disc;
                }
            }
        }
    }
    a.btn, .btn{
        margin: 0;
    }
    blockquote{
        font-size: rem-calc(26);
        line-height: rem-calc(32);
        font-weight: $medium;
        @include breakpoint(xl){
            font-size: rem-calc(48);
            line-height: rem-calc(60);
        }
    }
}

// guttnerburge default button
$btn-bg: var(--primary);
.wp-block-button{
   &__link {
        background-color: $btn-bg;
        color:$white;
        font-size:$font-size-base;
        line-height: 1.5;
        padding:rem-calc(7 12);
        font-weight:500;
        @include transition;


    }
    &.is-style-outline  {
        .wp-block-button__link{
            padding:rem-calc(5 12);
            border-color:var(--primary);
            color:var(--primary);
            &:hover {
                background-color: var(--primary);
                color:$white;
            }
        }
    }
}


// theme button
.btn {
    @include transition(all,.3s,linear);
    @include border(all,1px,solid,$primary-border);
    @include border-radius(0);
    text-align: center;
    padding: 15px;
    color: $white;
    font-weight: $medium;
    font-size: rem-calc(16);
    text-align: center;
    @include breakpoint(md){
        min-width: 136px;
    }
    &:hover{
        @include border(all,1px,solid,transparent);
    }
    &.btn-primary{
        &:hover{
            background: $alt;
            color: $white;
            text-decoration: underline;
        }
    }
    &.btn-sm{
        min-width: 145px;
        padding: 8px 12px;
    }
    &.btn-md{
        min-width: 145px;
        padding: 8px 12px;
    }
    &.btn-rounded{
        @include border-radius(3px);
    }
    &.btn-alt{
        background-color: #20bec6;
        border-color: #1ca9b0;
        font-size: rem-calc(14);
        &:hover{
            background-color: #3ad7df;
            border-color: #2dd5dd;
            color: $white;
            text-decoration: underline;
        }
    }

    &.btn-white{
        background: $white;
        color: $dark-gray;
        border: 1px solid rgb(229,231,235);
        &:hover{
            background: rgba(118,57,124,.2);
             color: $dark-gray;
        }
    }
    
    &:focus{
      box-shadow: none;
    }
    &.btn-outline{
        @include border(all,1px,solid,$dark-gray);
        &:hover{
            background: $dark-gray;
            color: $alt;
        }
    }
}
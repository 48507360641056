#js-amount-range[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  background-color: #F2F2F2;
  border-radius: 10px;
  background-image: linear-gradient(#77397D, #77397D);
  background-repeat: no-repeat;
  background-size: 0 100%;
  border-color: #E5E5E5;
  transition: unset;
  padding: 9px 0;

  &:focus {
    border-color: #E5E5E5;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 5px solid #20BFC7;
    background: #ffffff;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }

  &::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}

#js-amount-input {
  font-size: 1rem;
}
.rangeslider__fill{
    background: $purple;
}
.rangeslider__handle:after{
    background: $white;
    width: 24px;
    height: 24px;
}
.rangeslider__handle{
    background: none;
    border: 8px solid $primary;
}
.range-input{
    position: relative;
    &:before{
        content: '€';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
    }
    input{
        border:1px solid rgb(204,204,204);
        text-align: center;
        font-size: 14px;
        padding: 10px;
        height: 100%;
    }
}

.range-slider-area{
    padding: 30px 0;
    @include breakpoint(md) {
        border-radius: 40px;
        background: $white;
        margin-top: -40px;
        padding: 20px 40px;
    }
    input[type="submit"]{
        border-radius: 40px;
        
    }
}